import { Observable } from 'rxjs';
import { objectToFormData } from '@proliance-ai/utilities';
import { CustomerRequestData, CustomerRequestService } from '@services';
import { emailApiService } from '@services/api';

const sendCustomerRequest = (data: CustomerRequestData): Observable<Response> => {
  return emailApiService
    .customerRequest(objectToFormData(data));
}

export const customerRequestService: CustomerRequestService = {
  sendCustomerRequest
}
