// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-Consent_SH_80{text-align:justify;font-size:14px;line-height:21px}.title-Consent_HZgLm{margin-top:0;text-align:center;line-height:28px;font-size:24px;font-weight:500}.panel-Consent_QQuNo{width:100%;height:100%;cursor:pointer;height:auto !important;line-height:21px !important;font-size:14px !important;font-weight:400 !important;border-bottom:0 none !important;margin:0 0 16px !important;padding:0 0 0 16px !important}.panel-Consent_QQuNo:hover{background-color:#fff !important}.panel-Consent_QQuNo:before{left:0 !important}.last-Consent_NG1EJ{text-align:center;line-height:22px;font-size:17px;font-weight:500;margin-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content-Consent_SH_80",
	"title": "title-Consent_HZgLm",
	"panel": "panel-Consent_QQuNo",
	"last": "last-Consent_NG1EJ"
};
export default ___CSS_LOADER_EXPORT___;
