import { parseQueryParameters } from '@proliance-ai/design-system';
import { leadingCharacter } from '@proliance-ai/utilities';
import {
  companyIdParameterName,
  emailParameterName,
  redirectUrnParameterName,
  RouteState
} from '@router';

export interface GetRedirectParametersOptions {
  toState: RouteState;
  parameters: Record<string, boolean | number | string>;
  parseQuery?: boolean;
  addRedirectUrn?: boolean;
  addEmail?: boolean;
  addCompanyId?: boolean;
}

export const getRedirectParameters = (options: GetRedirectParametersOptions): Record<string, boolean | number | string> => {
  const {
    toState,
    parameters,
    parseQuery = true,
    addRedirectUrn = true,
    addEmail = true,
    addCompanyId = true
  } = options;
  const queryParameters = parseQuery
    ? parseQueryParameters()
    : {};
  const redirectUrn: string = leadingCharacter(toState.path, '/', false);
  const redirectUrnParameter: Record<string, string> = addRedirectUrn && redirectUrn
    ? { [redirectUrnParameterName]: redirectUrn }
    : {};

  const emailParameter: Record<string, string> = addEmail && toState.params[emailParameterName]
    ? { [emailParameterName]: decodeURIComponent(toState.params[emailParameterName]) }
    : {};
  const companyIdParameter: Record<string, number> = addCompanyId && toState.params[companyIdParameterName]
    ? { [companyIdParameterName]: toState.params[companyIdParameterName] }
    : {};
  return {
    ...companyIdParameter,
    ...queryParameters,
    ...emailParameter,
    ...redirectUrnParameter,
    ...parameters
  };
};
