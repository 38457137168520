import React, { ReactElement } from 'react';
import { classNameArrayToString, useDataAttributes } from '@proliance-ai/design-system';

interface IOwnProps {
  'data-key': string;
  active: string;
  disabled: boolean;
  label: string;
  clickHandler: (label: string) => void;
  className?: string;
}

type Props = IOwnProps;

const Tab = ({
 label,
 active,
 disabled,
 clickHandler,
 className,
 ...props
}: Props): ReactElement => {
  const key = props['data-key'];
  const isActive = key === active;
  const { dataAttributes } = useDataAttributes({
    test: {
      tab: key.toLowerCase(),
      'tab-active': isActive
    },
    guide: {
      tab: key.toLowerCase(),
      'tab-active': isActive
    },
  });

  const classNameString = classNameArrayToString([
    'tabItem',
    className,
    isActive && 'active',
    disabled && 'disabled'
  ]);

  const onClick = () => {
    if (!disabled) {
      clickHandler(key);
    }
  };

  return (
    <li
      className={classNameString}
      onClick={onClick}
      {...dataAttributes}
    >
      {label}
    </li>
  );
};

export default Tab;
