import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProductData,
  ProductEnumType,
  ProductType
} from '@proliance-ai/typings';
import { getProductLink as getProductLinkUtility } from '@proliance-ai/utilities';
import { productApiService } from '@services/api';
import { ProductService } from './product.typings';

export const productList: ProductType[] = [ 'academy', 'privacy', 'whistle', 'isms' ];

const getProlianceLink = () => (window as any).env.dataPrivacyPublicUrl;
const getProductLink = (key: ProductType): undefined | string => getProductLinkUtility(
  key,
  !!productData$.value
    ? productData$.value
    : undefined
);

const getProductsData = (suppressDefaultErrorHandler?: boolean | number[]): Observable<void> => productApiService.getProductsData(suppressDefaultErrorHandler)
  .pipe(
    map((productData: ProductData): void => {
      productData$.next(productData);
    })
  );
const productData$ = new BehaviorSubject<null | ProductData>(null);
const productsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(Object.entries(productData))
  : {};
const getProducts = (): ProductData => productsMapper(productData$.value);

const productsCountMapper = (productData: null | ProductData): number => Object.keys(productsMapper(productData)).length;
const getProductsCount = (): number => productsCountMapper(productData$.value);
const productsCount$ = productData$.pipe(map(productsCountMapper));

const isHasProductsMapper = (productData: null | ProductData): boolean => !!productsCountMapper(productData);
const getIsHasProducts = (): boolean => isHasProductsMapper(productData$.value);

const isSingleProductMapper = (productData: null | ProductData): boolean => !!productData && Object.keys(productData).length === 1;
const getIsSingleProduct = () => isSingleProductMapper(productData$.value);
const isSingleProduct$ = productData$.pipe(map(isSingleProductMapper));

const singleProductNameMapper = (productData: null | ProductData): false | ProductEnumType => !!productData && Object.keys(productData).length === 1 && Object.keys(productData)[0] as ProductEnumType;
const getSingleProductName = (): false | ProductEnumType => singleProductNameMapper(productData$.value);
const singleProductName$ = productData$
  .pipe(
    map(getSingleProductName)
  );

const isMultiProductMapper = (productData: null | ProductData): boolean => !!productData && Object.keys(productData).length > 1;
const getIsMultiProduct = () => isMultiProductMapper(productData$.value);
const isMultiProduct$ = productData$
  .pipe(
    map(isMultiProductMapper)
  );

const internalProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => !value.external)
  )
  : {};
const getInternalProducts = (): ProductData => internalProductsMapper(productData$.value);
const internalProducts$ = productData$
  .pipe(
    map(internalProductsMapper)
  );

const internalProductsCountMapper = (productData: null | ProductData): number => Object.keys(internalProductsMapper(productData)).length;
const getInternalProductsCount = (): number => internalProductsCountMapper(productData$.value);

const singleInternalProductNameMapper = (productData: null | ProductData): false | ProductEnumType => isSingleProductMapper(internalProductsMapper(productData)) && Object.keys(internalProductsMapper(productData))[0] as ProductEnumType;
const getSingleInternalProductName = (): false | ProductEnumType => singleInternalProductNameMapper(productData$.value);
const singleInternalProductName$ = productData$
  .pipe(
    map(singleInternalProductNameMapper)
  );

const ownedProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => value.owned)
  )
  : {};
const getOwnedProducts = (): ProductData => ownedProductsMapper(productData$.value);
const ownedProducts$ = productData$
  .pipe(
    map(ownedProductsMapper)
  );

const ownedProductsCountMapper = (productData: null | ProductData): number => Object.keys(ownedProductsMapper(productData)).length;
const getOwnedProductsCount = (): number => ownedProductsCountMapper(productData$.value);
const ownedProductsCount$ = productData$
  .pipe(
    map(ownedProductsCountMapper)
  );

const isHasOwnedProductsMapper = (productData: null | ProductData): boolean => !!ownedProductsCountMapper(productData);
const getIsHasOwnedProducts = () => isHasOwnedProductsMapper(productData$.value);
const isHasOwnedProducts$ = productData$
  .pipe(
    map(isHasOwnedProductsMapper)
  );

const offeredProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => !value.owned)
  )
  : {};
const getOfferedProducts = (): ProductData => offeredProductsMapper(productData$.value);
const offeredProducts$ = productData$
  .pipe(
    map(offeredProductsMapper)
  );

const offeredProductsCountMapper = (productData: null | ProductData): number => Object.keys(offeredProductsMapper(productData)).length;
const getOfferedProductsCount = (): number => offeredProductsCountMapper(productData$.value);
const offeredProductsCount$ = productData$
  .pipe(
    map(offeredProductsCountMapper)
  );

const isHasOfferedProductsMapper = (productData: null | ProductData): boolean => !!offeredProductsCountMapper(productData);
const getIsHasOfferedProducts = () => isHasOfferedProductsMapper(productData$.value);
const isHasOfferedProducts$ = productData$
  .pipe(
    map(isHasOfferedProductsMapper)
  );

export const productService: ProductService = {
  getProlianceLink,
  getProductLink,

  getProductsData,
  productData$,
  productsMapper,
  getProducts,

  productsCountMapper,
  productsCount$,
  getProductsCount,

  isHasProductsMapper,
  getIsHasProducts,

  isSingleProductMapper,
  getIsSingleProduct,
  isSingleProduct$,

  singleProductNameMapper,
  getSingleProductName,
  singleProductName$,

  isMultiProductMapper,
  getIsMultiProduct,
  isMultiProduct$,

  internalProductsMapper,
  getInternalProducts,
  internalProducts$,

  internalProductsCountMapper,
  getInternalProductsCount,

  singleInternalProductNameMapper,
  getSingleInternalProductName,
  singleInternalProductName$,

  ownedProductsMapper,
  getOwnedProducts,
  ownedProducts$,

  ownedProductsCountMapper,
  getOwnedProductsCount,
  ownedProductsCount$,

  isHasOwnedProductsMapper,
  getIsHasOwnedProducts,
  isHasOwnedProducts$,

  offeredProductsMapper,
  getOfferedProducts,
  offeredProducts$,

  offeredProductsCountMapper,
  getOfferedProductsCount,
  offeredProductsCount$,

  isHasOfferedProductsMapper,
  getIsHasOfferedProducts,
  isHasOfferedProducts$
};
