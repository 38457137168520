import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseModalButton, modalHandler, ModalOptions } from '@proliance-ai/react-ui';
import { redirect } from '@router';
import { Company, companyService } from '@services';
import { CompanySwitcherContent } from "@shared/components";
import { ProductSwitcherButton } from '../../ProductSwitcherButton';

export type UseSwitcherModalOptions = {
  id: string;
  currentCompany: null | Company;
  isMultiProduct: null | boolean;
  isMultitenancy: null | boolean;
}

export type UseCompanySwitcherModalOptions = (options: UseSwitcherModalOptions) => {
  modalOptions: ModalOptions;
};

const useCompanySwitcherModalOptions: UseCompanySwitcherModalOptions = (options) => {
  const {
    id,
    currentCompany,
    isMultiProduct,
    isMultitenancy
  } = options;

  const { t } = useTranslation();
  const actionsElement = (
    <CloseModalButton
      dataAttributesDictionary={ {
        test: { 'company-switcher': 'close' },
        guide: { 'company-switcher': 'close' }
      } }
    />
  );

  const secondaryActionsElement = (
    <ProductSwitcherButton
      isModalActionButton={ true }
      currentCompany={ currentCompany }
      isMultiProduct={ isMultiProduct }
      isMultitenancy={ isMultitenancy }
    />
  );

  const currentCompanyId = companyService.getCurrentCompanyId();
  const action = (companyId: number) => (): void => {
    if (currentCompanyId === companyId) {
      modalHandler()();
    } else {
      redirect({
        parameters: { companyId }
      });
    }
  };

  const modalOptions: ModalOptions = {
    id,
    size: 'large',
    dataAttributesDictionary: {
      test: { 'company-switcher': 'modal' },
      guide: { 'company-switcher': 'modal' }
    },
    heading: {
      title: t('companies.modalTitle')
    },
    content: {
      contentElement: <CompanySwitcherContent action={ action }/>,
      usePadding: false
    },
    footer: {
      actionsElement,
      secondaryActionsElement
    }
  };

  return { modalOptions };
};

export default useCompanySwitcherModalOptions;
