// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-CompanySwitcherContent_RhiiY{overflow:hidden;border:1px solid #e0e0e0;border-radius:4px;margin:1.5rem}.search-CompanySwitcherContent_yvLi3{margin:1rem}.divider-CompanySwitcherContent_AY4nv{border-bottom:1px solid #e0e0e0}.emptyText-CompanySwitcherContent_xJaUc{margin:auto;font-size:14px;font-weight:500;color:#979797}.listItem-CompanySwitcherContent_LqXjL{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-height:120px;max-height:175px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content-CompanySwitcherContent_RhiiY",
	"search": "search-CompanySwitcherContent_yvLi3",
	"divider": "divider-CompanySwitcherContent_AY4nv",
	"emptyText": "emptyText-CompanySwitcherContent_xJaUc",
	"listItem": "listItem-CompanySwitcherContent_LqXjL"
};
export default ___CSS_LOADER_EXPORT___;
