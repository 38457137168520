import { Observable } from 'rxjs';
import { Collection, CollectionParameters, Domain, DomainData } from '@proliance-ai/typings';
import { Api } from '@services/api';

const apiUrl = '/api/auth';

class DomainApiService extends Api {

  public getDomainCollection(parameters: CollectionParameters): Observable<Collection<Domain>> {
    return this.get<Collection<Domain>>({
      url: 'domain',
      parameters
    });
  }

  public addDomain(data: DomainData): Observable<Domain> {
    return this.post<Domain>({
      url: 'domain',
      body: data,
    });
  }

  public updateDomain(data: Domain): Observable<Domain> {
    return this.put<Domain>({
      url: `domain/${data.id}`,
      body: data,
    });
  }

  public deleteDomain(id: string): Observable<null> {
    return this.delete<null>({
      url: `domain/${id}`,
    });
  }
}

export const domainApiService = new DomainApiService(apiUrl);
