import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductEnumType, ProductEnum, ProductItem } from '@proliance-ai/typings';
import { Badge, classNameArrayToString, SVG, useDataAttributes } from '@proliance-ai/design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import styles from './ProductListItem.styl';

export interface IProductSwitcherProperties {
  productKey: ProductEnumType;
  product: ProductItem;
  isActive?: boolean;
}

type ProductSwitcherItemProperties = IProductSwitcherProperties & HTMLAttributes<HTMLDivElement>;

const ProductListItem: FC<ProductSwitcherItemProperties> = ({
  productKey,
  product: {
    recent
  },
  isActive,
  ...properties
}) => {
  const { t } = useTranslation('products');

  const product = ProductEnum[productKey];
  const title = t(`products.${ productKey }.title`);
  const recentText = t('translations.recent');
  const logo = t(`products.${ productKey }.logo`);
  const imageElement = recent
    ? (
      <Badge
        className={ styles.badge }
        color="danger"
        content={ recentText }
      >
        <SVG
          className={ styles.image }
          src={ logo }
        />
      </Badge>
    )
    : (
      <SVG
        className={ styles.image }
        src={ logo }
      />
    );
  const checkmarkElement = (
    <div className={ styles.checkmark }>
      <FontAwesomeIcon className={ styles.icon } icon={ faCheck }/>
    </div>
  );
  const contentElement = (
    <div className={ styles.imageWrap }>
      { checkmarkElement }
      { imageElement }
    </div>
  );

  const dataAttributesValue = { 'product': product };
  const { dataAttributes } = useDataAttributes({
    test: dataAttributesValue,
    guide: dataAttributesValue
  });

  const classNameString = classNameArrayToString([
    styles.productListItem,
    isActive && styles.active
  ]);

  return (
    <div
      className={ classNameString }
      { ...dataAttributes }
      { ...properties }
    >
      { contentElement }
      <div className={ styles.title }>
        { title }
      </div>
    </div>
  );
};

export default ProductListItem;
