import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { forkJoin, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import { isEmpty } from '@proliance-ai/utilities';
import { RouteDictionary } from '@interfaces';
import {
  getDynamicRoutePermission,
  getRouteListTypeByRouteName,
  ResolverData,
  routerDependencies,
  ResolveFactoryDictionary,
  RouteListType,
  RouteState,
  RouteStateData,
} from '@router';
import { permissionService } from '@services';

const getResolveFactoryDictionary = (
  resolverData: RouteDictionary<ResolveFactoryDictionary>
): ResolveFactoryDictionary =>
  (Object.keys(resolverData) as RouteListType[]).reduce((result: ResolveFactoryDictionary, route: RouteListType) => {
    if (!isEmpty(result)) {
      return result;
    }
    return permissionService.isPageAvailable(route)
      ? resolverData[route]!
      : result;
  }, {});

export const resolveMiddleware: ActivationFnFactory = (): ActivationFn => ( toState: State ): boolean | Promise<boolean> => {
  const { isLoggedIn, error: routeError } = toState as RouteState;
  if (routeError) {
    return true;
  }
  const dependency = routerDependencies[toState.name];
  if (typeof dependency === 'undefined') {
    return true;
  }
  if (typeof dependency.resolve === 'undefined' || !Object.keys(dependency.resolve).length) {
    return true;
  }

  const { dynamic } = dependency;
  const resolveFactoryDictionary: ResolveFactoryDictionary = dynamic
    ? getResolveFactoryDictionary(dependency.resolve as RouteDictionary<ResolveFactoryDictionary>)
    : (dependency.resolve as ResolveFactoryDictionary);
  const route = getRouteListTypeByRouteName(toState.name);
  const permission = dynamic
    ? getDynamicRoutePermission(Object.keys(dependency.resolve) as RouteListType[])
    : permissionService.getComponentsPermission(route);
  const data: ResolverData = { parameter: toState.params, permission, isLoggedIn };
  const resolveDictionary: Record<string, Observable<any>> = Object.keys(resolveFactoryDictionary).reduce(
    (result: Record<string, Observable<any>>, key: string) => ({
      ...result,
      [key]: resolveFactoryDictionary[key](data)
    }),
    {}
  );
  return new Promise((resolve: (value: boolean) => void) => forkJoin(resolveDictionary)
    .pipe(
      catchError((error: AjaxError) => {
        if (error.status === 401) {
          (toState as RouteState).error = 401;
          return of({});
        } else {
          (toState as RouteState).error = error.status === 403
            ? 403
            : error.status >= 500
              ? 500
              : error.status;
          return of({});
        }
      })
    )
    .subscribe((result: Record<string, any>) => {
      (toState as RouteState).data = {
        ...(toState as RouteState).data,
        ...(result as RouteStateData),
        permission,
        isLoggedIn,
        error: null
      };
      return resolve(true);
    })
  );
};
