import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { TitleOptions } from '@proliance-ai/design-system';
import { Namespace } from '@interfaces';
import { RouteListType, routerDependencies, routerMeta, RouteState } from '@router';
import { i18n, permissionService } from '@services';

const getDictionaryData = <T>(
  dictionaryData: Record<string, T>,
  fallback: T
): T => {
  const data: undefined | T = (Object.keys(dictionaryData) as RouteListType[])
    .reduce(
      (result: undefined | T, route: RouteListType) => {
        if (typeof result !== 'undefined') {
          return result;
        }
        return permissionService.isPageAvailable(route)
          ? dictionaryData[route] as T
          : result;
      },
      undefined
    ) || fallback;
  if (typeof data === 'undefined') {
    return fallback;
  } else {
    return data;
  }
};

export const metadataMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { error } = toState as RouteState;
  if (error) {
    return true;
  }
  const { dynamic } = routerDependencies[toState.name];
  const namespaceData = routerMeta[toState.name].namespace;
  const namespace: Namespace = dynamic
    ? getDictionaryData<Namespace>(namespaceData as Record<string, Namespace>, [])
    : namespaceData as Namespace;
  const titleOptionsData = routerMeta[toState.name].titleOptions;
  const titleOptions = dynamic
    ? getDictionaryData<TitleOptions>(titleOptionsData as Record<string, TitleOptions>, {})
    : titleOptionsData as TitleOptions;
  (toState as RouteState).data = {
    ...(toState as RouteState).data,
    ...routerMeta[toState.name],
    namespace,
    titleOptions
  };
  return i18n
    .loadNamespaces(namespace as string | readonly string[])
    .then(() => true);
};
