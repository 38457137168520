import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  componentFeatureMapping,
  ComponentListType,
  ComponentPermissionData,
  IPermission,
  pageComponentMapping,
  PermissionData
} from '@proliance-ai/typings';
import { FullRouteListType } from '@router';
import { PagePermissionData, PermissionService } from '@services';
import { permissionApiService } from '@services/api';

const permissionData$ = new BehaviorSubject<null | PermissionData>(null);
const componentPermission$ = new BehaviorSubject<null | ComponentPermissionData>(null);
const pagePermission$ = new BehaviorSubject<null | PagePermissionData>(null);

const getComponentsPermission = (route: FullRouteListType): ComponentPermissionData => {
  const routeMapping = pageComponentMapping[route];
  return routeMapping
    ? routeMapping
      .reduce((result: ComponentPermissionData, component: ComponentListType) => {
        if (componentPermission$.value) {
          result[component] = componentPermission$.value[component];
        }
        return result;
      }, {})
    : {};
};

const setComponentPermission = (permissionData: PermissionData): void => {
  const componentPermission = (Object.keys(componentFeatureMapping) as ComponentListType[])
    .reduce(
      (result: ComponentPermissionData, key: ComponentListType) => ({
        ...result,
        ...(permissionData[componentFeatureMapping[key]] && { [key]: permissionData[componentFeatureMapping[key]] })
      }),
      {}
    );
  componentPermission$.next(componentPermission);
};

const calculatePagePermission = (route: FullRouteListType): undefined | boolean => {
  const pageComponentList: undefined | ComponentListType[] = pageComponentMapping[route];
  const pageComponentsPermission =
    typeof pageComponentList === 'undefined'
      ? []
      : pageComponentList.reduce((result: boolean[], component: ComponentListType) => {
        const componentPermission: undefined | IPermission = componentPermission$.value![component];
        if (componentPermission) {
          result.push(componentPermission.read);
        }
        return result;
      }, []);
  return pageComponentsPermission.length
    ? pageComponentsPermission
      .some((value: boolean) => value)
    : undefined;
};

const setPagePermission = (): void => {
  const pagePermission = (Object.keys(pageComponentMapping) as FullRouteListType[]).reduce(
    (result: PagePermissionData, route: FullRouteListType) => {
      const permission = calculatePagePermission(route);
      if (typeof permission !== 'undefined') {
        result[route] = permission;
      }
      return result;
    },
    {}
  );
  pagePermission$.next(pagePermission);
};

const assignPermissionData = (suppressDefaultErrorHandler?: boolean | number[]): Observable<void> => permissionApiService.getPermissionData(suppressDefaultErrorHandler)
  .pipe(
    take(1),
    map((permissionData: PermissionData) => {
      permissionData$.next(permissionData);
      setComponentPermission(permissionData);
      setPagePermission();
    })
  );

const getIsPermissionsAvailable = (): boolean => permissionData$.value !== null;

const resetPermissionData = (): void => {
  permissionData$.next(null);
  componentPermission$.next(null);
  pagePermission$.next(null);
};

const isPageAvailable = (page: FullRouteListType): boolean => {
  const pagePermissionDictionary = pagePermission$.value;
  return !!(pagePermissionDictionary && pagePermissionDictionary[page]);
};

const isComponentAvailable = (component: ComponentListType): boolean => {
  const componentPermissionDictionary = componentPermission$.value;
  return !!(componentPermissionDictionary && componentPermissionDictionary[component]?.read);
};

export const permissionService: PermissionService = {
  permissionData$,
  componentPermission$,
  pagePermission$,

  getComponentsPermission,
  assignPermissionData,
  getIsPermissionsAvailable,
  resetPermissionData,

  isPageAvailable,
  isComponentAvailable
};
