import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentUser, User } from '@proliance-ai/typings';
import { classNameArrayToString } from '@proliance-ai/design-system';
import { getUserInitials, getUserTitle } from '@proliance-ai/utilities';
import { companyService, userColorService } from '@services';

import { faUserSlash } from '@fortawesome/pro-light-svg-icons';
import styles from './CardAvatar.styl';

interface Avatar {
  user: null | User | CurrentUser;
  className?: string;
  placeholder?: string;
}

const CardAvatar: FC<Avatar> = ({
  user,
  className,
  placeholder
}): ReactElement => {
  const { t } = useTranslation();
  if (!user) {
    const classNameString = classNameArrayToString([
      className,
      styles.avatar,
      styles.inactive,
      'no-select'
    ]);
    return (
      <div
        className={ styles.wrap }
        title={ placeholder }
      >
        <div className={ classNameString }>
          <FontAwesomeIcon
            className={ styles.icon }
            icon={ faUserSlash }
          />
        </div>
      </div>
    );
  }

  const { enabled } = user;

  const initials = getUserInitials(user);

  const { impersonatedBy } = user as CurrentUser;
  const isImpersonated = typeof impersonatedBy !== 'undefined';

  const classNameString = classNameArrayToString([
    className,
    styles.avatar,
    'no-select',
    isImpersonated && styles.impersonated,
    !enabled && styles.inactive
  ]);

  const titleAttribute = getUserTitle(t, user);

  const companyId = companyService.company$.value?.id;
  const avatar = initials
    ? (
      <div className={ classNameString } style={ { backgroundColor: userColorService.getColor(user, companyId) } }>
        <span className={ styles.initials }>{ initials }</span>
      </div>
    )
    : (
      <div className={ classNameString }>
        <span className={ styles.placeholder }>?</span>
      </div>
    );

  return (
    <div
      className={ styles.wrap }
      title={ titleAttribute }
    >
      { avatar }
    </div>
  );
};
export default memo(CardAvatar);
