import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataFormat } from 'select2';
import { Dropdown } from '@proliance-ai/design-system';
import { FREC, RefType } from '@interfaces';
import { languageList } from '@services';
import { useCombinedRefs } from '@utilities';
import { countryTemplateResult, countryTemplateSelection } from './';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  useFormGroup?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const LanguageDropdown: FREC<Props, HTMLSelectElement> = forwardRef((
  props: Props,
  ref: RefType<HTMLSelectElement>
) => {
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs<HTMLSelectElement>(ref, innerRef);
  const {
    disabled = false,
    useFormGroup = false,
    ...properties
  } = props;

  const { t, i18n } = useTranslation();

  const [ languageItems, setLanguageItems ] = useState<DataFormat[]>([]);

  useEffect(() => {
    const translatedLangList = languageList
      .map((id: string): DataFormat => ({
          id,
          text: t(`common:language.${ id }`)
        })
      );
    setLanguageItems(translatedLangList);
  }, [ t, i18n.language ]);

  const languageDropdownSettings = {
    allowClear: false,
    minimumResultsForSearch: 3,
    disabled,
    templateResult: countryTemplateResult,
    templateSelection: countryTemplateSelection
  };

  return (
    <Dropdown
      ref={ combinedRef }
      data={ languageItems }
      settings={ languageDropdownSettings }
      useFormGroup={ useFormGroup }
      value={ i18n.language }
      { ...properties }
    />
  );
});

export default LanguageDropdown;
