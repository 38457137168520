import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'react-router5';
import { Button } from '@proliance-ai/design-system';
import {
  ApplicationProduct,
  getLastPageStorageValue,
  isApplicationProduct,
  productRedirect,
  redirect, RouteListType
} from '@router';

const BackButton: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { route } = useRoute();

  const navigateBack = (): void => {
    const lastPage: null | ApplicationProduct | RouteListType = getLastPageStorageValue(route.name);
    if (lastPage) {
      if (isApplicationProduct(lastPage)) {
        productRedirect({ product: lastPage });
        return;
      }
      return redirect({ route: lastPage });
    }
    return redirect();
  };

  return (
    <Button
      preset="default"
      dataAttributesDictionary={ {
        test: { 'button-back': 'top' },
        guide: { 'button-back': 'top' }
      } }
      text={ t('button.back') }
      onClick={ navigateBack }
    />
  );
};

export default memo(BackButton);
