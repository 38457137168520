import { constants } from 'router5';
import { setRoute } from '@router/utilities';
import { routeList, RouteListType } from './routeList';
import { routeComponents as components } from './routeComponents';
import { routeResolver as resolver } from './routeResolvers';
import {
  getConditionalTitleOptions,
  getTitleKeyOptions
} from '@proliance-ai/design-system';

export const routes = [
  setRoute({
    name: 'errorPage',
    component: components.Error,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('404.pageTitle'),
    resolve: resolver.errorPage,
  }),
  setRoute({
    name: 'notAllowed',
    component: components.Error,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('403.pageTitle'),
    resolve: resolver.error(403),
  }),
  setRoute({
    name: constants.UNKNOWN_ROUTE as RouteListType,
    path: 'notFound',
    params: { path: routeList.notFound },
    component: components.Error,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('404.pageTitle'),
    resolve: resolver.error(),
  }),

  setRoute({
    name: 'default',
    lazy: false
  }),
  setRoute({
    name: 'login',
    component: components.Login,
    namespace: ['login', 'form'],
    titleOptions: getTitleKeyOptions('login.pageTitle'),
    resolve: resolver.login,
  }),
  setRoute({
    name: 'sso',
    component: components.Login,
    namespace: ['login', 'form'],
    titleOptions: getTitleKeyOptions('login.pageTitle'),
    resolve: resolver.login,
  }),
  setRoute({
    name: 'forgot',
    component: components.Forgot,
    namespace: ['forgot', 'form'],
    titleOptions: getTitleKeyOptions('pageTitle'),
    resolve: resolver.forgot
  }),
  setRoute({
    name: 'reset',
    component: components.Reset,
    namespace: ['reset', 'form'],
    titleOptions: {
      path: 'data',
      isConditional: true,
      trueTitleKey: 'password.pageTitle',
      falseTitleKey: 'password.invalid'
    },
    resolve: {
      ...resolver.reset,
    },
  }),
  setRoute({
    name: 'accountActivation',
    component: components.AccountActivation,
    namespace: ['accountActivation', 'form'],
    titleOptions: {
      path: 'userAccount',
      isConditional: true,
      trueTitleKey: 'pageTitle',
      falseTitleKey: 'invalid'
    },
    resolve: {
      ...resolver.accountActivation,
    },
  }),
  setRoute({
    name: 'courseInvitation',
    component: components.CourseInvitation,
    namespace: ['courseInvitation', 'form'],
    titleOptions: {
      path: 'courseNameTranslationInfo',
      optionKey: '',
      isConditional: true,
      isLocalizable: true,
      conditionalKey: ['en', 'de'],
      trueTitleKey: 'pageTitle',
      falseTitleKey: 'invalid'
    },
    resolve: {
      ...resolver.courseInvitation,
    },
  }),

  setRoute({
    name: 'companies',
    component: components.CompanySwitcher,
    namespace: ['companies', 'form'],
    titleOptions: getTitleKeyOptions('pageTitle'),
    resolve: {
      ...resolver.common
    },
  }),
  setRoute({
    name: 'products',
    component: components.ProductSwitcher,
    namespace: ['products', 'form'],
    titleOptions: getTitleKeyOptions('translations.title'),
    resolve: {
      ...resolver.common,
    },
  }),

  setRoute({
    name: 'profile',
    component: components.Profile,
    namespace: ['profile', 'form'],
    titleOptions: getConditionalTitleOptions(
      'userData',
      ['firstName', 'lastName'],
      'email',
      ' ',
      { isConditional: false }
    ),
    resolve: {
      ...resolver.common,
      ...resolver.profile
    },
  }),

  setRoute({
    name: 'profileTab',
    component: components.Profile,
    namespace: ['profile', 'form'],
    titleOptions: getConditionalTitleOptions(
      'userData',
      ['firstName', 'lastName'],
      'email',
      ' ',
      { isConditional: false }
    ),
    resolve: {
      ...resolver.common,
      ...resolver.profile
    },
  }),

  setRoute({
    name: 'onboarding',
    component: components.Onboarding,
    resolve: {
      ...resolver.common,
      ...resolver.onboarding,
    },
  }),

  setRoute({
    name: 'organization',
    component: components.Organization,
    resolve: {
      ...resolver.organization,
    },
  }),
  setRoute({
    name: 'organizationTab',
    namespace: 'organization',
    component: components.Organization,
    resolve: {
      ...resolver.organization,
    },
  }),

  setRoute({
    name: 'imprint',
    component: components.FooterDocument
  }),

  setRoute({
    name: 'privacyPolicy',
    component: components.FooterDocument
  }),

  setRoute({
    name: 'genderNotice',
    component: components.FooterDocument
  }),
  setRoute({
    name: 'consent',
    component: components.ConsentPage,
  }),
];
