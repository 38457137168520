import React, { Children, FC, memo, ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNameArrayToString } from '@proliance-ai/design-system';

import styles from './View.styl';

interface Props {
  current: number;
  children: ReactElement[];
}

const View: FC<Props> = ({ current, children }): ReactElement => {
  const list = Children.map(children, (child: ReactElement, index: number) => {
    const classNameString = classNameArrayToString([
      styles.item,
      current > index && styles.previous,
      current === index && styles.active,
      current < index && styles.next
    ]);
    const nodeRef = React.useRef(null);
    return (
      <CSSTransition
        nodeRef={ nodeRef }
        in={ current === index }
        timeout={ 500 }
        classNames="fade"
        appear={ true }
        unmountOnExit={ true }
      >
        <div ref={ nodeRef } className={ classNameString }>
          { child }
        </div>
      </CSSTransition>
    );
  });

  return (
    <div className={ styles.carousel }>
      { list }
    </div>
  );
};

export default memo(View);
