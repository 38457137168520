import { cloneElement, FC, ReactElement } from 'react';
import { ComponentListType, ComponentPermissionData, IPermission } from '@proliance-ai/typings';
import { Tabs } from '@shared/ui';
import { getComponentPermission, setPermission } from './utilities';

interface Props {
  permission: IPermission | ComponentPermissionData;
  componentName?: ComponentListType;
  children?: ReactElement;
  readonlyKey?: string;
  readonlyValue?: any;
}

export const PermissionGuard: FC<Props> = ({
  permission,
  componentName,
  children,
  readonlyKey,
  readonlyValue
}): ReactElement | null => {
  if (typeof children === 'undefined') {
    return null;
  }

  if (typeof componentName === 'undefined') {
    if (children.type === Tabs) {
      const props = children.props;
      const newTabList = props.children
        .reduce((result: ReactElement[], tab: ReactElement) => {
          const key = tab.props['data-permission-key'] || tab.props['data-key'];
          const keyArray = key.indexOf(',') > -1
            ? key.split(',')
            : [ key ];
          const value = keyArray
            .reduce((result: null | ReactElement, key: ComponentListType) => result !== null
                ? result
                : setPermission(tab, getComponentPermission(key, permission)),
              null
            );
          if (value !== null) {
            result.push(value);
          }
          return result;
        }, []);
      return cloneElement(children, { ...props, ...{ children: newTabList } });
    }
    return null;
  } else {
    const componentPermission = getComponentPermission(componentName, permission);
    return setPermission(children, componentPermission, readonlyKey, readonlyValue);
  }
};
