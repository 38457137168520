import { storageService } from '@proliance-ai/design-system';
import { ApplicationProduct, getLastPageStorageName, RouteListType } from '@router';

export const setLastPageStorageValue = (key: string, value: ApplicationProduct | RouteListType): void => {
  const storageName = getLastPageStorageName();

  const storageData = storageService.get<Record<string, ApplicationProduct | RouteListType>>(storageName);
  if (storageData !== null) {
    storageService.set(storageName, { ...storageData, [key]: value });
  } else {
    storageService.set(storageName, { [key]: value });
  }
};
