import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  take
} from 'rxjs/operators';
import { ComponentListType } from '@proliance-ai/typings';
import {
  OrganizationTab,
  ProfileTab,
  ResolverData,
  routeTabHandler
} from '@router';
import {
  emailResolver,
  getParameterResolver,
} from '../utilities';
import {
  authenticationService,
  newsResolver,
  surveyService,
  teamService,
  userService
} from '@services';


export const routeResolver = {
  error: (statusCode: number = 404) => ({
    errorCode: () => of(`${ statusCode }`)
  }),
  errorPage: {
    type: (data: ResolverData) => of(data.parameter.type),
    context: (data: ResolverData) => of(data.parameter.context),
    id: (data: ResolverData) => of(data.parameter.id)
  },

  login: {
    email: emailResolver((email: string) => of(email)),
    loginOptions: emailResolver((email: string) => authenticationService.getLoginOptions({ email })),
    companyId: getParameterResolver('companyId'),
    redirectUrn: getParameterResolver('redirectUrn'),
    externalRedirect: getParameterResolver('externalRedirect'),
    newsList: newsResolver,
    ssoProvider: getParameterResolver('ssoProvider', (value: string) => value.toUpperCase()),
    code: getParameterResolver('code')
  },

  forgot: {
    newsList: newsResolver,
    email: (data: ResolverData) => of(data.parameter.email)
  },

  reset: {
    token: (data: ResolverData) => of(data.parameter.token),
    data: (data: ResolverData) => {
      const token = data.parameter.token;
      if (!token) {
        return of(null);
      }
      return userService
        .getAccountActivation(token)
        .pipe(
          take(1),
          catchError((error: AjaxError) => {
            if (error.status === 400) {
              return of(false);
            }
            return of(null);
          })
        );
    }
  },

  common: {
    userData: () => userService.user$.pipe(take(1))
  },

  accountActivation: {
    token: (data: ResolverData) => of(data.parameter.token),
    userAccount: (data: ResolverData) =>
      userService.getAccountActivation(data.parameter.token)
        .pipe(
          take(1),
          catchError(() => of(null))
        )
  },
  courseInvitation: {
    token: (data: ResolverData) => of(data.parameter.token),
    courseNameTranslationInfo: (data: ResolverData) =>
      teamService.checkUserInvitationToken(data.parameter.token)
        .pipe(
          take(1),
          catchError(() => of(null))
        )
  },

  organization: {
    routeTab: (data: ResolverData) => routeTabHandler<OrganizationTab>('organization', data)
  },

  profile: {
    routeTab: (data: ResolverData) => routeTabHandler<ProfileTab>('profile', data),
    showConsent: (data: ResolverData) => of(data.parameter.showConsent)
  },

  onboarding: {
    userData: () => userService.user$.pipe(take(1)),
    surveyData: () => surveyService.getSurveyByName('onboarding')
  },

  staticPage: (usePermission: null | ComponentListType = null) => ({
    usePermission: () => of(usePermission)
  })
};
