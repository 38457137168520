import { forkJoin, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  retryWhen,
  share,
  take
} from 'rxjs/operators';
import { parseQueryParameters, tokenService } from '@proliance-ai/design-system';
import { notificationService } from '@proliance-ai/react-ui';
import { leadingCharacter } from '@proliance-ai/utilities';
import {
  companyIdParameterName,
  emailParameterName,
  errorParameterName,
  redirect,
  redirectUrnParameterName,
  router
} from '@router';
import { CompanyAddedMessage,
  CompanyRemovedMessage,
  CompanySwitchedMessage,
  CompanyUpdatedMessage,
  LogoutMessage,
  ErrorStream,
  CompanyUpdatedPayload,
  StreamEvent,
  ProductsUpdatedPayload,
  PermissionsUpdatedMessage,
  ProductsUpdatedMessage } from '@proliance-ai/typings';
import {
  companyService,
  permissionService,
  productService,
  StreamService,
  StreamMessage,
  userService
} from '@services';
import { streamApiService } from '@services/api';

let subscription: Subscription;

export const debounceTimeValue = 200;

const subject$ = new Subject<StreamMessage>();

const getRedirectParameters = (): Record<string, undefined | null | number | string> => {
  const { email } = userService.user$.value || {};
  const companyId = companyService.getCurrentCompanyId();
  const path = router().getState()?.path || '';
  const redirectUrn = leadingCharacter(path.split('?')[0], '/', false);
  return {
    ...parseQueryParameters(),
    ...(email && { [emailParameterName]: email }),
    ...(redirectUrn && redirectUrn !== 'login' && { [redirectUrnParameterName]: redirectUrn }),
    ...(companyId && { [companyIdParameterName]: companyId }),
    [errorParameterName]: 401
  };
};

const streamErrorHandler = (error: ErrorStream): void => {
  streamApiService
    .healthCheck()
    .pipe(
      retryWhen((errors: Observable<AjaxError>) => errors
        .pipe(
          mergeMap((ajaxError: AjaxError) => {
            if (ajaxError.status === 401) {
              return throwError(ajaxError);
            } else {
              return timer(error.reconnectDelay);
            }
          })
        )
      ),
      catchError((): Observable<null> => of(null))
    )
    .subscribe((response: null | number): void => {
      if (response === null) {
        const parameters = getRedirectParameters();
        redirect({ route: 'login', parameters });
        return;
      } else {
        connect();
      }
    });
};

const connect = (): void => {
  const stream = streamApiService
    .getStream()
    .pipe(
      catchError((error: ErrorStream): Observable<null> => {
        streamErrorHandler(error);
        return of(null);
      }),
      share()
    );
  subscription = stream
    .subscribe((message: null | StreamMessage): void => {
      if (message) {
        subject$.next(message);
      }
    });
};

const unsubscribe = (): void => {
  if (subscription) {
    subscription.unsubscribe();
  }
};

const reconnect = (): void => {
  unsubscribe();
  connect();
};

const isCompanyAddedMessage = (message: StreamMessage): message is CompanyAddedMessage => message.type === StreamEvent.COMPANY_ADDED;
const subscribeCompanyAddedMessage = (): Observable<CompanyAddedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanyAddedMessage));
subscribeCompanyAddedMessage()
  .subscribe((): void => {
    companyService.updateCurrentCompany()
      .subscribe();
  });
const isCompanyRemovedMessage = (message: StreamMessage): message is CompanyRemovedMessage => message.type === StreamEvent.COMPANY_REMOVED;
const subscribeCompanyRemovedMessage = (): Observable<CompanyRemovedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanyRemovedMessage));
subscribeCompanyRemovedMessage()
  .subscribe((message: CompanyRemovedMessage): void => {
    const { payload: { currentCompany } } = message;
    if (currentCompany) {
      return redirect({
        route: 'companies',
        parameters: {
          notification: 'companyRemoved'
        }
      });
    } else {
      companyService.updateCurrentCompany()
        .subscribe();
    }
  });
const isCompanySwitchedMessage = (message: StreamMessage): message is CompanySwitchedMessage => message.type === StreamEvent.COMPANY_SWITCHED;
const subscribeCompanySwitchedMessage = (): Observable<CompanySwitchedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanySwitchedMessage));
subscribeCompanySwitchedMessage()
  .subscribe((message: CompanySwitchedMessage): void => {
    const { payload: { currentCompanyId } } = message;
    const companyId = companyService.getCurrentCompanyId();
    if (companyId && +companyId === +currentCompanyId) {
      return;
    }
    redirect({
      route: 'default',
      parameters: {
        companyId: currentCompanyId,
        notification: 'companySwitched'
      }
    });
  });
const isCompanyUpdatedMessage = (message: StreamMessage): message is CompanyUpdatedMessage => message.type === StreamEvent.COMPANY_UPDATED;
const defaultCompanyUpdatedPayload: CompanyUpdatedPayload = {
  currentCompany: false,
  permissionsUpdated: false,
  draftChanged: false
};
let companyUpdatedPayload: CompanyUpdatedPayload = defaultCompanyUpdatedPayload;
const subscribeCompanyUpdatedMessage = (): Observable<CompanyUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isCompanyUpdatedMessage),
    map((message: CompanyUpdatedMessage): CompanyUpdatedMessage => {
      companyUpdatedPayload = {
        currentCompany: companyUpdatedPayload.currentCompany || message.payload.currentCompany,
        permissionsUpdated: companyUpdatedPayload.permissionsUpdated || message.payload.permissionsUpdated,
        draftChanged: companyUpdatedPayload.draftChanged || message.payload.draftChanged
      };
      message.payload = companyUpdatedPayload;
      return message;
    }),
    debounceTime(debounceTimeValue)
  );
subscribeCompanyUpdatedMessage()
  .subscribe((message: CompanyUpdatedMessage): void => {
    companyUpdatedPayload = defaultCompanyUpdatedPayload;
    const { payload: { currentCompany, permissionsUpdated } } = message;
    if (!currentCompany) {
      companyService
        .updateAvailableCompaniesList()
        .subscribe();
      return;
    }
    forkJoin(
      permissionsUpdated
        ? [ companyService.updateCurrentCompany(), permissionService.assignPermissionData(), productService.getProductsData() ]
        : [ companyService.updateCurrentCompany(), productService.getProductsData() ]
    )
      .pipe(take(1))
      .subscribe((): void => {
        const dataAttributesDictionary = {
          test: { notificationWarning: 'companyUpdated' },
          guide: { notificationWarning: 'companyUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.companyUpdated',
          dataAttributesDictionary
        });
      });
  });

const isPermissionsUpdatedMessage = (message: StreamMessage): message is PermissionsUpdatedMessage => message.type === StreamEvent.PERMISSIONS_UPDATED;
const subscribePermissionsUpdatedMessage = (): Observable<PermissionsUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isPermissionsUpdatedMessage),
    debounceTime(debounceTimeValue)
  );
subscribePermissionsUpdatedMessage()
  .subscribe((message: PermissionsUpdatedMessage): void => {
    const { payload: { currentCompany } } = message;
    if (!currentCompany) {
      return;
    }
    permissionService.assignPermissionData()
      .pipe(take(1))
      .subscribe((): void => {
        const dataAttributesDictionary = {
          test: { notificationWarning: 'permissionsUpdated' },
          guide: { notificationWarning: 'permissionsUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.permissionsUpdated',
          dataAttributesDictionary
        });
      });
  });

const isProductsUpdatedMessage = (message: StreamMessage): message is ProductsUpdatedMessage => message.type === StreamEvent.PRODUCTS_UPDATED;
const defaultProductsUpdatedPayload: ProductsUpdatedPayload = {
  currentCompany: false,
  permissionsUpdated: false
};
let productsUpdatedPayload: ProductsUpdatedPayload = defaultProductsUpdatedPayload;
const subscribeProductsUpdatedMessage = (): Observable<ProductsUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isProductsUpdatedMessage),
    map((message: ProductsUpdatedMessage): ProductsUpdatedMessage => {
      productsUpdatedPayload = {
        currentCompany: productsUpdatedPayload.currentCompany || message.payload.currentCompany,
        permissionsUpdated: companyUpdatedPayload.permissionsUpdated || message.payload.permissionsUpdated
      };
      message.payload = productsUpdatedPayload;
      return message;
    }),
    debounceTime(debounceTimeValue)
  );
subscribeProductsUpdatedMessage()
  .subscribe((message: ProductsUpdatedMessage): void => {
    productsUpdatedPayload = defaultProductsUpdatedPayload;
    const { payload: { currentCompany, permissionsUpdated } } = message;
    if (!currentCompany) {
      return;
    }
    forkJoin(
      permissionsUpdated
        ? [ companyService.updateCurrentCompany(), permissionService.assignPermissionData(), productService.getProductsData() ]
        : [ companyService.updateCurrentCompany(), productService.getProductsData() ]
    )
      .pipe(take(1))
      .subscribe((): void => {
        const dataAttributesDictionary = {
          test: { notificationWarning: 'productsUpdated' },
          guide: { notificationWarning: 'productsUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.productsUpdated',
          dataAttributesDictionary
        });
      });
  });

const isLogoutMessage = (message: StreamMessage): message is LogoutMessage => message.type === StreamEvent.LOGOUT;
const subscribeLogoutMessage = (): Observable<LogoutMessage> => subject$
  .asObservable()
  .pipe(filter(isLogoutMessage));
subscribeLogoutMessage()
  .subscribe((message: LogoutMessage): void => {
    const token = message.payload?.token;
    if (token) {
      const currentToken = tokenService.getToken();
      if (!!currentToken && currentToken !== token) {
        return;
      }
    }
    window.addEventListener(
      'pageshow',
      (event: PageTransitionEvent): void => {
        if (event.persisted) {
          location.reload();
        }
      }
    );
    const parameters = getRedirectParameters();
    return redirect({ route: 'login', parameters });
  });

export const streamService: StreamService = {
  connect,
  unsubscribe,
  reconnect,

  subscribeCompanyAddedMessage,
  subscribeCompanyRemovedMessage,
  subscribeCompanySwitchedMessage,
  subscribeCompanyUpdatedMessage,
  subscribePermissionsUpdatedMessage,
  subscribeProductsUpdatedMessage
};
