import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Locale } from '@proliance-ai/typings';
import { Case, configurationService, kebabCaseToSnakeCase, pageTitleService } from '@proliance-ai/design-system';
import { getStaticLocalizationResourceUrl } from '@proliance-ai/utilities';
import { languageList } from './languageList';

const apiVersion = (window as any).env.apiVersion;
const environmentPrefix = (window as any).env?.environment || 'local';
const parameterName = `${ environmentPrefix }AcceptLanguage`;
const queryString = apiVersion
  ? `?version=${ apiVersion }`
  : '';
const staticNamespaces = [ 'products', 'customerRequest' ];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (language: Locale, namespaceList: string[]): string[] => namespaceList
        .map((namespace: string): string => staticNamespaces.includes(namespace)
          ? getStaticLocalizationResourceUrl({ locale: language, name: `${ namespace }.json` })
          : `/i18n/${ language }/${ namespace }.json${ queryString }`
        ),
      allowMultiLoading: true
    },
    parseMissingKeyHandler: () => '',
    detection: {
      order: [ 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag' ],
      lookupQuerystring: 'language',
      cookieDomain: configurationService.getDomain(),
      lookupCookie: parameterName,
      lookupLocalStorage: parameterName,
      caches: [ 'localStorage', 'cookie' ],
      excludeCacheFor: [ 'cimode' ]
    },
    supportedLngs: languageList,
    ns: [ 'common', 'form' ],
    defaultNS: 'common',
    fallbackLng: false,
    returnEmptyString: true,
    interpolation: {
      escapeValue: false,
      format: (value: string, format?: string) => {
        if (format === 'capitalize') {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (format === 'enum') {
          return kebabCaseToSnakeCase(value, Case.upper);
        }
        return value;
      }
    },
    load: 'languageOnly',
    react: {
      useSuspense: false
    },
    debug: false
  });

const languageIgnoreList = [ 'dev', 'cimode', undefined ];

const checkLanguage = (language: string): string => {
  if (languageIgnoreList.includes(language) || typeof language === 'undefined') {
    language = languageList[0];
  }
  if (language.indexOf('-') > -1) {
    language = language.split('-')[0];
  }
  if (!i18n.languages.includes(language)) {
    i18n.changeLanguage(language);
  }

  return language;
};

pageTitleService.setI18n(i18n);

i18n.on('languageChanged', (language: string) => {
  i18n.language = checkLanguage(language);
  setHtmlLangAttribute(language);
  pageTitleService.updateTitle();
});

i18n.on('loaded', () => pageTitleService.updateTitle());

const setHtmlLangAttribute = (language: string): void => {
  document.documentElement.lang = language;
};

export { i18n };
