import { BehaviorSubject } from 'rxjs';
import { CurrentUser } from '@proliance-ai/typings';
import { ConsentService, Resolution } from './consent.typings';
import { deviceService } from '@services';

const apiKey: string = '7c46ef93-ef39-4812-409b-3ad6e33ad6f4';

const isInitialized$ = new BehaviorSubject<boolean>(false);
const isUploaded$ = new BehaviorSubject<boolean>(false);
const error$ = new BehaviorSubject<boolean>(false);

const isEnabled = (): boolean => !!JSON.parse((window as any).env.enablePendo || 'false');

const getResolution = (): Resolution => {
  const {
    width: screenWidth,
    height: screenHeight
  } = deviceService.getScreenDimensions();
  const {
    width: windowWidth,
    height: windowHeight
  } = deviceService.getWindowDimensions();
  return {
    windowX: windowWidth,
    windowY: windowHeight,
    screenX: screenWidth,
    screenY: screenHeight
  };
};

const setGuidesDisabled = (value: boolean = true): void => {
  (window as any).pendo.setGuidesDisabled(value);
  (window as any).pendo.stopGuides();
};

const loadGuides = (): void => {
  (window as any).pendo.loadGuides();
  (window as any).pendo.startGuides();
};

const setUser = (user: CurrentUser): void => {
  if (!isEnabled()) {
    return;
  }
  if (!isUploaded$.value) {
    if (user.analyticsAllowed !== false) {
      initialize(user);
    }
    return;
  } else {
    const {
      id: userId,
      currentCompany,
      analyticsAllowed
    } = user;
    const { id: companyId } = currentCompany || {};

    const isConsentAnswered = typeof analyticsAllowed !== 'undefined';
    const options = {
      visitor: {
        id: `${ userId }-${ companyId }`
      },
      account: {
        id: companyId
      }
    };
    if (!isInitialized$.value) {
      (window as any).pendo
        .initialize({
          disableGuides: !isConsentAnswered
        });
      isInitialized$.next(true);
    } else {
      if (!isConsentAnswered) {
        setGuidesDisabled();

      } else if (analyticsAllowed) {
        loadGuides();
      }
    }
    (window as any).pendo.identify(options);
    (window as any).pendo.track('Resolution', getResolution());
  }
};

const initialize = (user: CurrentUser): void => {
  if (!isEnabled() || user.analyticsAllowed === false) {
    return;
  }
  if (isUploaded$.value) {
    setUser(user);
    return;
  }
  const pendo = (window as any).pendo = (window as any).pendo || {};
  pendo._q = pendo._q || [];
  const actionList = [
    'initialize',
    'identify',
    'updateOptions',
    'pageLoad',
    'track'
  ];
  actionList
    .map((action: string, index: number) => {
      const method = !!index
        ? 'unshift'
        : 'push';
      const initializeOptions = {
        disableGuides: typeof user?.analyticsAllowed === 'undefined'
      };
      // tslint:disable-next-line:only-arrow-functions
      pendo[action] = pendo[action] || function() {
        const args = !!index
          ? arguments
          : [ initializeOptions ];
        pendo._q[method]([ action, ...args ]);
      };
    });

  const script = document.createElement('script');
  script.src = `https://cdn.eu.pendo.io/agent/static/${ apiKey }/pendo.js`;
  script.async = true;
  script.onload = () => {
    // pendo.enableDebugging();
    isUploaded$.next(true);
    setUser(user);
  };

  const firstScript = document.getElementsByTagName('script')[0];
  if (firstScript !== null && firstScript.parentNode !== null) {
    firstScript.parentNode.insertBefore(script, firstScript);
  }
};

export const consentService: ConsentService = {
  isInitialized$,
  isUploaded$,
  error$,
  isEnabled,
  initialize,
  setUser,
  setGuidesDisabled,
  loadGuides
};
