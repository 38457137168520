import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState
} from 'react';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import {
  ITableState,
  Input,
  Scrollbar,
  Loading,
  debounce
} from '@proliance-ai/design-system';
import { Collection } from '@interfaces';
import { companyService, Company } from '@services';
import { useCurrentCompany } from '@hooks';
import { CompanySwitcherItem } from './components';

import styles from './CompanySwitcherContent.styl';

interface OwnProps {
  action: (companyId: number) => () => void;
}

type Props = OwnProps;

const CompanySwitcherContent: FC<Props> = ({ action }) => {

  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ search, setSearch ] = useState<string>('');
  const [ companyList, setCompanyList ] = useState<Company[]>([]);
  const [ availableCompanies, setAvailableCompanies ] = useState<Company[]>([]);

  const unsubscribe$ = new Subject<void>();
  const currentCompany = useCurrentCompany(unsubscribe$);

  useEffect(() => {
    companyService.availableCompaniesList$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((companyList: Company[]) => setCompanyList(companyList));
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const fetchData = (parameters: Partial<ITableState<Company>> = {}) => {
    setIsLoading(true);
    companyService.getAvailableCompanyCollection(parameters)
      .pipe(
        take(1),
        takeUntil(unsubscribe$)
      )
      .subscribe((data: Collection<Company>) => {
        setAvailableCompanies(data.elements);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData({
      filters: [ {
        id: 'name',
        value: search
      } ]
    });
  }, [ search, companyList ]);

  const debounceSearchOnChangeHandler = debounce(
    (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    300
  );

  const searchElement = (
    <Input
      className={ styles.search }
      name="searchCompany"
      label={ t('companies.searchLabel') }
      placeholder={ t('companies.searchPlaceholder') }
      useFormGroup={ false }
      autoComplete="none"
      dataAttributesDictionary={ {
        test: { 'company-switcher': 'search' },
        guide: { 'company-switcher': 'search' }
      } }
      defaultValue={ search }
      onChange={ debounceSearchOnChangeHandler }
    />
  );

  if (!currentCompany) {
    return null;
  }

  const listItemElement = availableCompanies.map(
    (item: Company, key: number) => (
      <CompanySwitcherItem
        key={ key }
        item={ item }
        currentCompany={ currentCompany }
        onClick={ action(item.id) }
      />
    )
  );

  const emptyElement = availableCompanies.length
    ? null
    : <div className={ styles.emptyText }>
      { t('companies.noResults') }
    </div>;

  const loadingElement = isLoading
    ? <Loading size={ 32 }/>
    : null;

  const listElement = (
    <Scrollbar
      usePadding={ false }
      scrollYOffset={ {
        top: 8,
        bottom: 8
      } }
      minHeight={ 120 }
    >
      <div className={ styles.listItem }>
        { listItemElement }
        { emptyElement }
        { loadingElement }
      </div>
    </Scrollbar>
  );

  return (
    <div className={ styles.content }>
      { searchElement }
      <div className={ styles.divider }></div>
      { listElement }
    </div>
  );
};

export default CompanySwitcherContent;
