import i18next from 'i18next';
import { notificationService } from '@proliance-ai/react-ui';
import { capitalize } from '@proliance-ai/utilities';

export type NotificationType = 'error' | 'warn' | 'info' | 'success';

export interface ShowNotificationOptions {
  type: NotificationType;
  namespace?: string;
  textTranslationPrefix?: string;
  textTranslationKeyList: Array<number | string>;
}

export const showNotification = (
  options: ShowNotificationOptions
): boolean => {
  const {
    type,
    namespace = 'common',
    textTranslationPrefix,
    textTranslationKeyList
  } = options;
  const attemptList: boolean[] = textTranslationKeyList
    .map((key: number | string): boolean => {
      const textTranslationKey = textTranslationPrefix
        ? `${ textTranslationPrefix }.${ key }`
        : `${ key }`;
      const translationExists = i18next.exists(`${ namespace }:${ textTranslationKey }`);
      if (translationExists) {
        const dataAttributesValue = { [`notification${ capitalize(type) }`]: key }
        const dataAttributesDictionary = {
          test: dataAttributesValue,
          guide: dataAttributesValue
        };
        notificationService[type]({
          namespaces: namespace,
          textTranslationKey,
          dataAttributesDictionary,
        });
        return true;
      }
      return false;
    });
  if (attemptList.every((result: boolean): boolean => !result)) {
    return false;
  }
  const isAbsentLocalisationsPresent: boolean = attemptList
    .some((result: boolean): boolean => !result);
  if (isAbsentLocalisationsPresent) {
    attemptList
      .map((result: boolean, index: number): void => {
        if (!result) {
          console.error(`Localization for notification key "${ textTranslationKeyList[index] }" not found in "${ i18next.language }" locale`);
        }
      });
  }
  return true;
};
