import { useEffect } from 'react';
import { RouteState } from '@router';
import { useScrollDispatch } from '@proliance-ai/design-system';

export type UseScrollUpdateOptions = {
  route: RouteState;
}

export const useScrollUpdate = (options: UseScrollUpdateOptions): void => {
  const { route } = options;

  const update = useScrollDispatch();

  useEffect(
    () => {
      update();
    },
    [route]
  );
}
