import { ComponentListType, IPermission, isIPermission, ComponentPermissionData } from "@proliance-ai/typings";

export const getComponentPermission = (
  component: ComponentListType,
  permission?: IPermission | ComponentPermissionData
): undefined | IPermission => {
  if (!permission) {
    return undefined;
  }
  if (isIPermission(permission)) {
    return permission;
  }
  return permission[component] as IPermission;
};
