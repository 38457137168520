// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".companySwitcherItem-CompanySwitcherItem_CijPL{padding:12px 1rem;-webkit-transition:background-color .2s ease-in,box-shadow .2s ease-in;transition:background-color .2s ease-in,box-shadow .2s ease-in;cursor:pointer}.companySwitcherItem-CompanySwitcherItem_CijPL.isActive-CompanySwitcherItem_LSuY4{background-color:#f7f7f7}.companySwitcherItem-CompanySwitcherItem_CijPL.isActive-CompanySwitcherItem_LSuY4 .title-CompanySwitcherItem_imaKN{color:#1b143f}.companySwitcherItem-CompanySwitcherItem_CijPL.isActive-CompanySwitcherItem_LSuY4 .imageWrap-CompanySwitcherItem_vBPHp{background:#38a7d7;border:1px solid #38a7d7;box-shadow:0 0 0 4px rgba(56,167,215,0.2)}.companySwitcherItem-CompanySwitcherItem_CijPL:hover{background-color:#f2f2f2}.companySwitcherItem-CompanySwitcherItem_CijPL .image-CompanySwitcherItem_FjcQ3{max-width:175px;margin:0 auto}.companySwitcherItem-CompanySwitcherItem_CijPL .imageWrap-CompanySwitcherItem_vBPHp{position:relative;padding:1.5rem;margin-bottom:1.5rem;border:1px solid #e0e0e0;border-radius:16px;box-shadow:0 3px 8px rgba(0,0,0,0.09);cursor:pointer}.companySwitcherItem-CompanySwitcherItem_CijPL .title-CompanySwitcherItem_imaKN{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;font-size:12px;font-weight:500;color:#4f4f4f}.companySwitcherItem-CompanySwitcherItem_CijPL .title-CompanySwitcherItem_imaKN svg{width:14px;height:14px;margin-left:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companySwitcherItem": "companySwitcherItem-CompanySwitcherItem_CijPL",
	"isActive": "isActive-CompanySwitcherItem_LSuY4",
	"title": "title-CompanySwitcherItem_imaKN",
	"imageWrap": "imageWrap-CompanySwitcherItem_vBPHp",
	"image": "image-CompanySwitcherItem_FjcQ3"
};
export default ___CSS_LOADER_EXPORT___;
