// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalButton-ProductSwitcherButton_nVfLj{padding:7px 48px;gap:8px}.modalButton-ProductSwitcherButton_nVfLj .logo-ProductSwitcherButton_blMc6{height:24px}.modalButton-ProductSwitcherButton_nVfLj .icon-ProductSwitcherButton_DBZcM{width:16px;height:16px}.button-ProductSwitcherButton_eMy95{width:239px;height:40px;position:relative;-webkit-transition:width .2s linear;transition:width .2s linear;cursor:pointer}.button-ProductSwitcherButton_eMy95 .iconButton-ProductSwitcherButton_IUKto,.button-ProductSwitcherButton_eMy95 .logoButton-ProductSwitcherButton_Rc5tg,.button-ProductSwitcherButton_eMy95 .logoButtonIcon-ProductSwitcherButton_OOBJo{margin:auto;-webkit-transition:opacity .2s linear;transition:opacity .2s linear}.button-ProductSwitcherButton_eMy95 .iconButton-ProductSwitcherButton_IUKto,.button-ProductSwitcherButton_eMy95 .logoButton-ProductSwitcherButton_Rc5tg{position:absolute;top:0;right:0;bottom:0;left:0}.button-ProductSwitcherButton_eMy95 .logoButton-ProductSwitcherButton_Rc5tg{width:197px;height:38px;margin-left:40px}.button-ProductSwitcherButton_eMy95 .iconButton-ProductSwitcherButton_IUKto{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\";filter:alpha(opacity=0);opacity:0}.button-ProductSwitcherButton_eMy95 .logoButtonIcon-ProductSwitcherButton_OOBJo{color:#1b143f;width:1.5rem;height:1.5rem;position:absolute;top:0;bottom:0;left:0}.button-ProductSwitcherButton_eMy95.disabled-ProductSwitcherButton_ioBMv{cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalButton": "modalButton-ProductSwitcherButton_nVfLj",
	"logo": "logo-ProductSwitcherButton_blMc6",
	"icon": "icon-ProductSwitcherButton_DBZcM",
	"button": "button-ProductSwitcherButton_eMy95",
	"iconButton": "iconButton-ProductSwitcherButton_IUKto",
	"logoButton": "logoButton-ProductSwitcherButton_Rc5tg",
	"logoButtonIcon": "logoButtonIcon-ProductSwitcherButton_OOBJo",
	"disabled": "disabled-ProductSwitcherButton_ioBMv"
};
export default ___CSS_LOADER_EXPORT___;
