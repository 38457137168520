import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { leadingCharacter } from '@proliance-ai/design-system';
import {
  getPageType,
  getPath,
  getRouteData,
  isApplicationProduct,
  PageType,
  productParameterName,
  productStorageName,
  redirectUrnParameterName,
  removeProductStorageValue,
  removeStorageRedirectData,
  RouteState,
  setProductStorageValue,
  setStorageRedirectData
} from '@router';

export const redirectParameterHandler: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { name, path, params } = toState as RouteState;
  const productParameter = params[productParameterName];
  const redirectParameter = params[redirectUrnParameterName];

  if (isApplicationProduct(productParameter)) {
    if (name === 'login') {
      setProductStorageValue(productParameter);
      if (redirectParameter) {
        setStorageRedirectData(productStorageName, redirectParameter.toString());
      }
    } else {
      removeProductStorageValue();
      removeStorageRedirectData(productStorageName);
    }
  } else if (redirectParameter) {
    removeProductStorageValue();
    removeStorageRedirectData(productStorageName);

    const redirectPath = leadingCharacter(getPath(redirectParameter), '/');
    const targetState = getRouteData(redirectPath);
    if (targetState !== null && getPageType(targetState.name) !== PageType.public) {
      setStorageRedirectData(getPath(path), redirectParameter);
    }
  }
  return true;
};
