import { Api } from '@services/api';
import { Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { CurrentUser } from '@proliance-ai/typings';
import { RequestOptions } from '@proliance-ai/design-system';
import { Collection, ITableParameters } from '@interfaces';
import {
  EmailNotification,
  Company,
  UserAccountData,
  UserAccountType,
  getRequestParametersFromTableParameters,
} from '@services';

const apiUrl: string = '/api/survey/user';

class UserApiService extends Api {

  public getCurrentUser(token: null | string, suppressDefaultErrorHandler?: boolean | number[]): Observable<null | CurrentUser> {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      return this.get({ headers, suppressDefaultErrorHandler });
    }
    return of(null);
  }

  public updateCurrentUser(body: Partial<CurrentUser>): Observable<CurrentUser> {
    return this.put({ body });
  }

  public updateAnalyticsAllowed(value: boolean, options?: RequestOptions): Observable<CurrentUser> {
    const parameters = {
      analyticsAllowed: value
    };
    return this.patch({
      url: 'analytics',
      parameters,
      ...options
    });
  }

  public updateLanguage(value: string): Observable<CurrentUser> {
    const parameters = {
      preferredLanguage: value
    };
    return this.patch({
      url: 'language',
      parameters
    });
  }

  public updateTimeZone(value: string): Observable<CurrentUser> {
    const parameters = {
      timeZone: value
    };
    return this.patch({
      url: 'timezone',
      parameters
    });
  }

  public resetPassword(email: string): Observable<string | AjaxError> {
    return this.delete({
      url: 'password',
      parameters: { email },
      suppressDefaultErrorHandler: [400]
    });
  }

  public setPassword(token: string, password: string): Observable<null> {
    return this.post({
      url: 'password',
      body: { token, password }
    });
  }

  public getAccountActivation(token: string): Observable<UserAccountType> {
    return this.get<UserAccountType>({ url: `token/${token}`, suppressDefaultErrorHandler: true  });
  }

  public setAccountActivation(body: UserAccountData): Observable<UserAccountType> {
    return this.post({
      url: 'activation',
      body
    });
  }

  public getAvailableCompanyCollection(
    tableParameters: Partial<ITableParameters<Company>> = {},
    suppressDefaultErrorHandler?: boolean | number[]
  ): Observable<Collection<Company>> {
    const parameters = Object.keys(tableParameters).length
      ? getRequestParametersFromTableParameters(tableParameters)
      : tableParameters;
    return this.get<Collection<Company>>({
      url: 'available-companies',
      parameters,
      suppressDefaultErrorHandler
    });
  }

  public setCurrentCompany(companyId: number): Observable<Company> {
    return this.patch<Company>({
      url: `current-company/${companyId}`,
      suppressDefaultErrorHandler: [404]
    });
  }

  public setTwoFactorAuthorization(tfaEnabled: boolean): Observable<CurrentUser> {
    return this.patch({
      url: 'tfa',
      parameters: { tfaEnabled }
    });
  }

  public getEmailIntervalData(): Observable<EmailNotification[]> {
    return this.get({
      url: 'email-notification'
    });
  }

  public updateEmailIntervalData(data: EmailNotification[]): Observable<EmailNotification[]> {
    return this.post({
      url: 'email-notification',
      body: data
    });
  }
}

export const userApiService = new UserApiService(apiUrl);
