import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductEnumType, ProductData, ProductItem } from '@proliance-ai/typings';
import { Button } from '@proliance-ai/design-system';
import { modalHandler, modalService } from '@proliance-ai/react-ui';
import { useProductSwitcherModalOptions, UseSwitcherModalOptions } from '../../hooks';
import { WhistleNoteModalContent } from '..';
import { ProductListItem } from '..';

import styles from './ProductList.styl';

interface IOwnProps {
  options?: UseSwitcherModalOptions;
  data: ProductData;
}

type Props = IOwnProps;

const ProductList: FC<Props> = ({ options, data }): ReactElement => {
  const { t } = useTranslation('products');
  const modalOptions = options
    ? useProductSwitcherModalOptions(options).modalOptions
    : options;
  const navigate = (item: ProductItem): void => {
    const { link, external } = item;
    if (external) {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  };

  const openWhistleModal = (item: ProductItem): void => {
    const cancelHandler = (): void => {
      if (modalOptions) {
        modalService.show(modalOptions);
      } else {
        modalService.hide();
      }
    };
    const confirmHandler = (): void => {
      navigate(item);
    };
    const actionsElement = (
      <>
        <Button
          dataAttributesDictionary={ {
            test: { 'whistle-note': 'close' },
            guide: { 'whistle-note': 'close' }
          } }
          preset="default"
          text={ t('translations.whistleModalCancel') }
          onClick={ cancelHandler }
        />
        <Button
          dataAttributesDictionary={ {
            test: { 'whistle-note': 'confirm' },
            guide: { 'whistle-note': 'confirm' }
          } }
          preset="primary"
          text={ t('translations.whistleModalConfirm') }
          onClick={ modalHandler(confirmHandler) }
        />
      </>
    );

    modalService.show({
      id: 'whistleNoteModal',
      size: 'large',
      dataAttributesDictionary: {
        test: { 'whistle-note': 'modal' },
        guide: { 'whistle-note': 'modal' }
      },
      heading: {
        title: t('translations.whistleModalTitle')
      },
      content: {
        contentElement: <WhistleNoteModalContent/>,
        usePadding: false,
        minHeight: 0
      },
      footer: {
        actionsElement
      }
    });
  };

  const navigateHandler = (key: ProductEnumType, item: ProductItem) => (): void => {
    if (key === 'WHISTLE' && item.owned) {
      return openWhistleModal(item);
    }
    navigate(item);
  };

  const productItemList = Object.entries(data)
    .map((entry) => {
      const [ key, product ] = entry as [ ProductEnumType, ProductItem ];
      const onClick = navigateHandler(key, product);
      return <ProductListItem
        key={ key }
        isActive={ false }
        productKey={ key as ProductEnumType }
        product={ product }
        onClick={ onClick }
      />;
    });

  return (
    <div className={ styles.productList }>
      { productItemList }
    </div>
  );
};

export default memo(ProductList);
