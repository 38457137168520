import React, { ChangeEvent, useState, useEffect, forwardRef, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs/operators';
import { Options } from 'select2';
import { DataAttributesType, Dropdown } from '@proliance-ai/design-system';
import { FREC, IDictionaryResponse, TranslationInfo, Locale, RefType } from '@interfaces';
import { dictionaryService } from '@services';
import { getSorter, useCombinedRefs } from '@utilities';
import countryListMapper from './countryListMapper';
import countryTemplateSelection from './countryTemplateSelection';
import countryTemplateResult from './countryTemplateResult';

import './CountryDropdown.styl';

interface Props {
  name?: string;
  value?: string;
  dataAttributesDictionary?: DataAttributesType;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  isMultiple?: boolean;
}

const CountryDropdown: FREC<Props, HTMLSelectElement> = forwardRef((
  props: Props,
  ref: RefType<HTMLSelectElement>
) => {
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs<HTMLSelectElement>(ref, innerRef);
  const {
    name = 'countryDropdown',
    value,
    onChange,
    onBlur = () => undefined,
    disabled,
    isMultiple = false,
    dataAttributesDictionary = {},
    ...properties
  } = props;
  const { i18n, t } = useTranslation('form');
  const locale = i18n.language as Locale;

  const {
    test = { country: 'dropdown' },
    guide = { country: 'dropdown' }
  } = dataAttributesDictionary;

  const [ countryList, setCountryList ] = useState<Array<IDictionaryResponse<TranslationInfo>>>([]);

  useEffect(() => {
    dictionaryService
      .getData('country')
      .pipe(take(1))
      .subscribe((countries: Array<IDictionaryResponse<TranslationInfo>>) => setCountryList(countries));
  }, []);

  const sorter = getSorter({ locale, compareById: false });

  const getSettings = (): Options => ({
    width: '100%',
    allowClear: true,
    placeholder: '',
    disabled,
    containerCssClass: 'dse-country-dropdown-container',
    dropdownCssClass: 'dse-country-dropdown-dropdown',
    templateSelection: countryTemplateSelection,
    templateResult: countryTemplateResult(
      combinedRef.current!,
      isMultiple
    ),
    sorter,
    multiple: isMultiple
  });

  const [ settings, setSettings ] = useState(getSettings());

  useEffect(
    () => {
      setSettings(getSettings());
    },
    [ combinedRef.current ]
  );

  return (
    <>
      { locale && (
        <Dropdown
          ref={ combinedRef }
          dataAttributesDictionary={ { test, guide } }
          useFormGroup={ false }
          data={ countryListMapper(countryList, locale) }
          settings={ settings }
          value={ value }
          onChange={ onChange }
          onBlur={ onBlur }
          name={ name }
          label={ t('country') }
          { ...properties }
        />
      ) }
    </>
  );
});

export default memo(CountryDropdown);
