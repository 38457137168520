import { storageService } from '@proliance-ai/design-system';
import { leadingCharacter } from '@proliance-ai/utilities';
import { redirectUrnStorageName } from '@router';

export const getStorageRedirectData = (key: string): null | string => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData === null) {
    return null;
  }
  return typeof storageData[key] === 'undefined'
    ? null
    : leadingCharacter(storageData[key], '/');
};
