import { configurationService } from '@proliance-ai/design-system';

export const errorParameterName = 'error';
export const notificationParameterName = 'notification';

export const externalRedirectParameterName = 'externalRedirect';
export const externalRedirectStorageName = 'externalRedirect';

export const emailParameterName = 'email';

export const companyIdParameterName = 'companyId';
export const companyIdStorageName = 'companyId';

export const openModalParameterName = 'openModal';

export const redirectUrnParameterName = configurationService.getRedirectUrnParameterName();
export const redirectUrnStorageName = 'redirectData';
export const redirectParameterName = 'redirected';

export const productParameterName = 'product';
export const productStorageName = 'product';

export const lastPageStorageName = 'lastPage';

