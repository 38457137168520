import { Observable } from 'rxjs';
import {
  Api as AbstractApi,
  CreateRequestOptions,
  getRequestInterceptors,
  tokenRequestInterceptor,
  languageRequestInterceptor,
  httpRequest,
  getResponseDataInterceptorPipe,
  apiVersionResponseInterceptor,
  getResponseErrorInterceptorPipe
} from '@proliance-ai/design-system';
import { errorResponseInterceptor } from './interceptors';
import './utilities';

export class Api extends AbstractApi {
  public createRequest<T>(options: CreateRequestOptions): Observable<T> {
    return this.createRequestConfig(options)
      .pipe(
        getRequestInterceptors([
          tokenRequestInterceptor,
          languageRequestInterceptor
        ]),
        httpRequest,
        getResponseDataInterceptorPipe([apiVersionResponseInterceptor]),
        getResponseErrorInterceptorPipe([errorResponseInterceptor])
      ) as Observable<T>;
  }
}
