import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SsoProvider, TokenResponse } from '@proliance-ai/typings';
import { Credentials, LoginOptions, LoginOptionsData, TfaRequestData } from '@services';
import { Api } from '@services/api';

const apiUrl: string = '/api/survey/auth';

class AuthenticationApiService extends Api {
  public getLoginOptions(parameters: LoginOptionsData): Observable<LoginOptions> {
    return this.get<LoginOptions>({
      url: '/api/auth/login-options',
      parameters,
      ignoreApiUrl: true
    });
  }

  public login(body: Credentials): Observable<TokenResponse> {
    return this.post<TokenResponse>({
      url: 'login',
      body,
    });
  }

  public ssoLogin(ssoProvider: SsoProvider, parameters: Record<string, string>): Observable<TokenResponse> {
    return this.post<TokenResponse>({
      url: `login/sso/${ssoProvider}`,
      parameters
    });
  }

  public tfaResend(accessToken: string): Observable<number> {
    return this.post<number>({
      url: 'tfa-code',
      body: { accessToken },
      returnType: 'status'
    });
  }

  public tfaVerification(body: TfaRequestData): Observable<null | TokenResponse> {
    return this.post<TokenResponse>({
      url: 'tfa-verification',
      body
    })
      .pipe(catchError(() => of(null)));
  }

  public logout(token: string): Observable<null> {
    const headers = { Authorization: `Bearer ${token}` };
    return this.get<null>({
      url: 'logout',
      headers,
      suppressDefaultErrorHandler: true
    });
  }
}

export const authenticationApiService = new AuthenticationApiService(apiUrl);
