import {
  ForwardRefExoticComponent,
  MutableRefObject,
  PropsWithoutRef,
  RefAttributes
} from 'react';
import { Filters, SortingRule } from 'react-table';
import { IdTextPair, OptGroupData, OptionData } from 'select2';
import { ComponentListType } from '@proliance-ai/typings';
import { RouteListType } from '@router/configuration';

export enum TaskType {
  ACADEMY = 'ACADEMY',
  DPO_NOMINATION = 'DPO_NOMINATION',
  DPO_PRIVACY_POLICY = 'DPO_PRIVACY_POLICY',
  DPO_NOTIFICATION = 'DPO_NOTIFICATION',
  DEFINE_TEAM = 'DEFINE_TEAM',
  PROCESS_SELECTION = 'PROCESS_SELECTION',
  ADDITIONAL_PROCESS_SELECTION = 'ADDITIONAL_PROCESS_SELECTION',
  PROCESS_CONTEXT = 'PROCESS_CONTEXT',
  AUDIT_CONCEPT = 'AUDIT_CONCEPT',
  PROCESS_DETAIL = 'PROCESS_DETAIL,',
  ACTION = 'ACTION',
  ACADEMY_INVITE_EMPLOYEE = 'ACADEMY_INVITE_EMPLOYEE',
}

export type TaskTypeType = keyof typeof TaskType;

export enum TaskTypeDecode {
  dpoNomination = 'DPO_NOMINATION',
  dpoPrivacyPolicy = 'DPO_PRIVACY_POLICY',
  dpoNotification = 'DPO_NOTIFICATION',
  defineTeam = 'DEFINE_TEAM',
  processSelection = 'PROCESS_SELECTION',
  additionalProcessSelection = 'ADDITIONAL_PROCESS_SELECTION',
  processContext = 'PROCESS_CONTEXT',
  auditConcept = 'AUDIT_CONCEPT',
  action = 'ACTION',
  processDetail = 'PROCESS_DETAIL',
  academy = 'ACADEMY',
  academyInviteEmployee = 'ACADEMY_INVITE_EMPLOYEE',
}

export enum FunctionalArea {
  HR = 'HR',
  IT = 'IT',
  SALES = 'SALES',
  PURCHASE = 'PURCHASE',
  FINANCE = 'FINANCE',
  SECURITY = 'SECURITY',
  THIRD_PARTY_PROCESSING = 'THIRD_PARTY_PROCESSING',
}

export type FunctionalAreaType = keyof typeof FunctionalArea;

export type RouteDictionary<T> = {
  [key in RouteListType]?: T;
};

export enum Locale {
  en = 'en',
  de = 'de',
}

type LocaleType = keyof typeof Locale;

export type TranslationData = {
  [key in LocaleType]: string;
};

export type SortDirection = 'asc' | 'desc';

export interface ICollectionParameters {
  page?: number;
  pageSize?: number;
  sortDirection?: string;
  sortField?: string;
  [key: string]: string | string[] | string[][] | number | undefined;
}

export interface Collection<T> {
  elements: T[];
  totalResult: number;
  pageSize: number;
  currentPage: number;
  sortField: string;
  sortDirection: string;
}

export interface IDictionaryResponse<T> {
  id: string;
  title: T;
}

export interface IFilter {
  id: string;
  value: any;
  pivotId?: string;
}

export interface ITableParameters<T extends {}> {
  page?: number;
  pageSize?: number;
  currentPage?: number;
  sortBy: Array<SortingRule<T>>;
  filters: Filters<T>;
}

export type ITranslationData<T> = { [P in Locale]: T };
export type TranslationInfo = ITranslationData<string>;
export type PluralKey = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other';
export type ITranslationPlural = {
  [key in PluralKey]?: string;
};
export type ITranslationPluralInfo = { [P in Locale]: ITranslationPlural };

export interface IErrorStreams extends Event {
  reconnectDelay: number;
}

export type OptionDataType = IdTextPair | OptGroupData | OptionData;

export type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export type FREC<P, E> = ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<E>>;

export type Namespace = string | string[];
export type NamespaceData = Namespace | Record<string, Namespace>;

export interface IComponentDictionary {
  [key: string]: ComponentListType | ComponentListType[];
}

export enum Errors {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  hasNoSymbol = 'hasNoSymbol',
  hasSymbol = 'hasSymbol',
  hasDigit = 'hasDigit',
  hasLowerCase = 'hasLowerCase',
  hasUpperCase = 'hasUpperCase',
  isEqual = 'isEqual',
  email = 'email',
  phone = 'phone',
  isExists = 'isExists',
  dpmRole = 'dpmRole',
  tpp = 'tpp',
}

export interface IFieldValidationResult {
  error: Errors;
  parameters?: Record<string, string | number>;
}

export type ErrorStatus
  = 400
  | 401
  | 403
  | 404
  | 500
  | 502
  | 'unknown';

export type Mapper<T, R> = (data: T) => R;
